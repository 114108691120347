import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Seo from '../components/seo'
import {aboutHolder, aboutLHS, aboutRHS} from '../styles/PageStyles.module.scss'
import {motion} from 'framer-motion'
import PageTitle from '../components/PageTitle'

export default function AboutPage() {
  const paraVariants = {
    pre: {
      y: "4rem",
      opacity:0
    },
    vis: {
      y: "0rem",
      opacity:1,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut"
      }
    },
    post: {
      y: "4rem",
      opacity:0,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut"
      }
    }
  }
  

  return (
    <article className={aboutHolder}>
      <Seo title="About Dot Allison" />
      <PageTitle>About Dot Allison</PageTitle>
      <section>
        <motion.div className={aboutLHS} variants={paraVariants} >
          <StaticImage
            src="../images/About Dot pic.jpg"
            alt="About Dot Allison"
            placeholder="blurred"
            layout="constrained"
            width={660}
            height={880}
          />
        </motion.div>
        <div className={aboutRHS}>

        <motion.p variants={paraVariants}>Having taken time out to raise a family, Dot Allison returns with Heart-Shaped Scars, her most realised and illuminating album – and there have been several significant predecessors to compare it to.</motion.p>
 
        <motion.p variants={paraVariants}>Since her debut solo album Afterglow in 1999, Allison has strived to, keep the listener on a journey – and herself too. She revolts against what she has done before, to evolve and not just occupy the same space.  That journey has taken her from Afterglow’s broad church (trip-hop, Tim Buckley-esque ballads, dance tracks, chilled psychedelia) to the sultry synth-pop of We Are Science (2002), the lush, baroque Exaltation Of Larks (2007) and the eclectic, rootsy drama of Room 7½ (2009). The range of guest stars on Allison’s records is equally broad: where else would you find a cast list that includes Kevin Shields, Hal David, Paul Weller, Pete Doherty and Darren Emerson. Likewise, Allison’s own guest roles with the likes of Massive Attack, Scott Walker, Slam, Philip Shepard, The Babyshambles & Pete Doherty, underlining the huge respect her peers hold her in.</motion.p>
          
        <motion.p variants={paraVariants}>Nature itself gets a credit on Heart-Shaped Scars, namely field recordings of birdsong, rivers and also the ambience of the sea from the Hebridean Islands. Allison, who lives in Edinburgh, has a cottage there; it’s also the location for regular gatherings amongst folk musician pals (Sarah Campbell and Amy Bowman included), “sharing ideas and passing instruments between us all, amongst friends and the island community,” says Allison. “It’s where I first sang ‘Long Exposure’ in public at a folk house-concert. So, I can definitely hear some of the Hebrides in Heart-Shaped Scars.” </motion.p>
          
        <motion.p variants={paraVariants}>Allison also hears elements of The Wicker Man film soundtrack (she once sung ‘Gently Johnny’ at Glastonbury) and the influence of folk-minded artists in her record collection, such as Linda Perhacs, Karen Dalton, Gene Clark, Neil Young, Joni Mitchell, Carole King, Nick Drake, Opal, Mazzy Star, Brian Wilson and, most poignantly, the late Andy Weatherall. “Andy championed, signed and mentored me,” she says. “He gave me so many compilations that broadened my musical tastes… I hear his influence throughout all of my albums.” </motion.p>
        
        <motion.p variants={paraVariants}>Heart-Shaped Scars may have the richness and metaphorical depth of poetry but it’s balanced out by classic tropes of singer-songwriters through the ages. The sentiments behind ‘Cue The Tears’, ‘Love Died In Our Arms’ and ‘Goodbye’ are direct appeals from the heart; melodically too, they chime with torch-singing and soul traditions. Allison’s father was a botanist, and her mother a musician; eventually, the DNA of music took this former bio-chemistry student in a very different direction – and with good reason too. “To me, music is a sort of tonic or an antidote to a kind of longing, for a while at least,” she concludes. </motion.p>
          
        <motion.p variants={paraVariants}>Heart-Shaped Scars has that very same impact: an antidote to stress, a beautiful, restorative and inspiring balm for these times.</motion.p>

        </div>
      </section>
    </article>
  )
}
